import styled from 'styled-components';
import { grey } from '../../../../styles/colors';
import { Exo } from '../../../../styles/fonts';
import { fromMedium } from '../../../../styleUtils/mediaQueries';

export const PersonWrapper = styled.div`
    color: ${grey};
    margin-bottom: 10px;
    font-family: ${Exo};
    text-align: center;
    ${fromMedium`
      text-align: left;
  `};
`;
