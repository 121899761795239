import styled from 'styled-components';
import { fromLarge, fromMedium, toLarge } from '../../../../styleUtils/mediaQueries';
import { gridTemplateColumns, gridTemplateRows, gridDisplay } from 'styleUtils/grid';

export const Wrapper = styled.div`
    padding: 32px 0;
    ${gridDisplay()}

    ${toLarge`
      ${gridTemplateColumns('40% 60%')}
      ${gridTemplateRows('auto auto')}
  `};

    ${fromMedium`
      margin: 68px 0;
    `};

    ${fromLarge`
      padding: 100px 0;

      ${gridTemplateColumns('30% 70%')}
      ${gridTemplateRows('auto auto')}
      
      width: 100%;
      margin: 0 auto;
  `};
`;
