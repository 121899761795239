import React from 'react';
import { Button } from 'components/common/Button';
import { ButtonColor } from '../../../types/button';
import { ContactForm } from 'components/contact/ContactForm';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Wrapper } from './atoms/Wrapper';
import { logModalView } from 'helpers/analytics';

export const ContactFormModal: React.FC = () => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const toggleModal = (): void => {
        setModalIsOpen(!modalIsOpen);
    };

    React.useEffect(() => {
        if (modalIsOpen) {
            logModalView(`${window.location.pathname}#contactForm`);
        }
    }, [modalIsOpen]);

    return (
        <Wrapper>
            <Button color={ButtonColor.orange} more={true} onClick={toggleModal} tabIndex={0}>
                Stuur een bericht
            </Button>

            <Modal isOpen={modalIsOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Stuur een bericht</ModalHeader>
                <ModalBody>
                    {/* TODO: Add a proper contact form, instead of a copied job offer form. */}
                    <ContactForm />
                </ModalBody>
            </Modal>
        </Wrapper>
    );
};
