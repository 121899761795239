import styled from 'styled-components';

export const Description = styled.div`
    margin-top: 0;
    text-align: justify;
    h1 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
        color: black;
    }
`;
