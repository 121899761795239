import styled from 'styled-components';
import { fromMedium } from '../../../../styleUtils/mediaQueries';
import { gridColumn, gridRow } from 'styleUtils/grid';

export const TextWrapper = styled.div`
    padding: 0 32px;
    ${gridColumn({ start: 1, end: 3 })}
    ${gridRow({ start: 2 })}

    ${fromMedium`

      ${gridColumn({ start: 2 })}
      ${gridRow({ start: 1 })}
  `};
`;
