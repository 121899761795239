import styled from 'styled-components';
import { fromMedium } from 'styleUtils/mediaQueries';

export const Wrapper = styled.div`
    margin-top: 20px;
    text-align: center;
    ${fromMedium`
    text-align: left;
  `};
`;
