import React from 'react';
import api from 'api/api';
import { Button } from 'components/common/Button';
import { Form, Row, Col, Alert } from 'reactstrap';
import { FormControls } from 'types/form';
import { FormGroupWithKey } from 'components/forms/FormGroupWithKey';
import { Icon } from 'components/common/Icon';
import { JobOfferApplication } from 'types/jobOffer';
import { initialState } from './initialState';
import { changeHandler, createFormControlCopy, createFormControlsCopy, setValidationFormControl } from 'utils/form';

export const ContactForm: React.FC = () => {
    const [formControls, setFormControls] = React.useState<FormControls>(initialState);
    const [isPosting, setIsPosting] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isFailed, setIsFailed] = React.useState(false);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): any =>
        changeHandler(event, formControls, setFormControls);

    const sendJobApplication = async (jobApplication: JobOfferApplication): Promise<void> => {
        const formData = new FormData();

        // Adding all the form data to the FormData to be able to send the resume too.
        Object.keys(jobApplication).forEach((key) => formData.append(key, jobApplication[key]));

        setIsPosting(true);

        try {
            await api('/vacancies/apply', {
                method: 'post',
                data: formData,
                'Content-Type': 'multipart/form-data',
            });

            setIsPosting(false);
            setIsSuccess(true);
        } catch (error) {
            setIsPosting(false);
            setIsFailed(true);
        }
    };

    const submit = (): void => {
        event.preventDefault();

        let isValid = true;

        const updatedControls = createFormControlsCopy(formControls);

        const jobApplication: JobOfferApplication = {} as JobOfferApplication;
        jobApplication.jobId = 650; // TODO: Remove this once this becomes a proper contact form.

        for (const key in updatedControls) {
            const updatedFormElement = createFormControlCopy(key, formControls);
            updatedFormElement.touched = true;

            setValidationFormControl(updatedFormElement);

            isValid = isValid && updatedFormElement.valid;

            jobApplication[key] = updatedFormElement.value;

            updatedControls[key] = updatedFormElement;
        }

        setFormControls(updatedControls);

        if (isValid) {
            sendJobApplication(jobApplication);
        }
    };

    return (
        <>
            {!isSuccess && (
                <Form>
                    <Row>
                        <Col xs="12" lg="6">
                            <FormGroupWithKey
                                label="Voornaam"
                                formControl={formControls['firstName']}
                                changeHandler={handleChange}
                                name="firstName"
                                disabled={isPosting}
                            />
                        </Col>
                        <Col xs="12" lg="6">
                            <FormGroupWithKey
                                label="Achternaam"
                                formControl={formControls['lastName']}
                                changeHandler={handleChange}
                                name="lastName"
                                disabled={isPosting}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <FormGroupWithKey
                                label="Woonplaats"
                                formControl={formControls['address']}
                                changeHandler={handleChange}
                                name="address"
                                disabled={isPosting}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6">
                            <FormGroupWithKey
                                label="Telefoon"
                                formControl={formControls['phone']}
                                changeHandler={handleChange}
                                name="phone"
                                disabled={isPosting}
                            />
                        </Col>
                        <Col xs="12" sm="6">
                            <FormGroupWithKey
                                label="E-mail"
                                formControl={formControls['email']}
                                changeHandler={handleChange}
                                name="email"
                                disabled={isPosting}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <FormGroupWithKey
                                label="CV (in PDF formaat)"
                                formControl={formControls['resume']}
                                changeHandler={handleChange}
                                inputProps={{ type: 'file' }}
                                name="resume"
                                disabled={isPosting}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <FormGroupWithKey
                                label="Bericht"
                                formControl={formControls['motivation']}
                                changeHandler={handleChange}
                                inputProps={{ type: 'textarea', rows: 6 }}
                                name="motivation"
                                disabled={isPosting}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs="12">
                            <a href="https://developers.nl/static/pdf/privacy-verklaring.pdf" rel="noopener noreferrer" target="_blank">
                                <Icon content="\f35d" /> Privacyverklaring
                            </a>
                        </Col>
                        <Col xs="12" className="py-2">
                            <FormGroupWithKey
                                check
                                inline
                                label="Ja, ik stem in met het bewaren van mijn gegevens en de privacyverklaring."
                                formControl={formControls['consent']}
                                changeHandler={handleChange}
                                inputProps={{ type: 'checkbox' }}
                                name="consent"
                                disabled={isPosting}
                            />
                        </Col>
                        <Col xs="12">
                            <Button className="mt-2" type="submit" onClick={submit} disabled={isPosting} tabIndex={0}>
                                Verstuur
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}

            {isSuccess && <Alert color="success">Uw sollicitatie is verstuurd!</Alert>}

            {isFailed && (
                <Alert className="mt-2" color="danger">
                    Oeps er ging iets verkeerd. Probeer het later opnieuw
                </Alert>
            )}
        </>
    );
};
