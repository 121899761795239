import { css, CSSObject } from 'styled-components';

export const onIe = (templateLiteral: TemplateStringsArray | CSSObject, ...interpolations: any): string => {
    const cssProps = css(templateLiteral, ...interpolations);

    return (css`
        body.ie & {
            ${cssProps}
        }
    ` as unknown) as string;
};
