import React from 'react';

import Markdown from 'markdown-to-jsx';
import { FormatTeams } from 'components/common/FormatTeams';
import { WebImage } from 'components/common/WebImage';
import { Avatar } from 'components/common/Avatar';
import { ContactFormModal } from 'components/contact/ContactFormModal';
import { Description } from './atoms/Description';
import { IconGridWrapper } from './atoms/IconGridWrapper';
import { IconLinkGrid } from '../../common/IconLinkGrid';
import { PersonWrapper } from './atoms/PersonWrapper';
import { ProfileWrapper } from './atoms/ProfileWrapper';
import { TeamUser } from 'types/teamUser';
import { TextWrapper } from './atoms/TextWrapper';
import { Wrapper } from './atoms/Wrapper';

interface Props {
    profile: {
        user: TeamUser;
        leadContent: string;
        title: string;
        id: number;
    };
    contact?: boolean;
    socialLinks?: {
        meetUrl: string;
        phone: string;
        email: string;
    };
}

export const LetsMeet: React.FC<Props> = (props) => {
    const {
        profile: { user, leadContent },
    } = props;

    const { linkedInProfileUrl, phone, publicEmail, imageAttachments, fullname, teams } = user;

    const { image } = imageAttachments[0] || {};

    return (
        <Wrapper>
            <ProfileWrapper>
                <Avatar border="2px" size="130px">
                    <WebImage image={image} />
                </Avatar>
                <IconGridWrapper>
                    <IconLinkGrid meetUrl={linkedInProfileUrl} phone={phone} email={publicEmail} />
                </IconGridWrapper>
            </ProfileWrapper>
            <TextWrapper>
                <PersonWrapper>
                    {fullname}
                    {' - '}
                    <FormatTeams teams={teams} />
                </PersonWrapper>
                <Description>
                    <Markdown>{leadContent}</Markdown>
                </Description>
                <ContactFormModal />
            </TextWrapper>
        </Wrapper>
    );
};

export default LetsMeet;
