import styled from 'styled-components';
import { fromMedium, fromSmall, fromExtraLarge } from '../../../../styleUtils/mediaQueries';
import { gridDisplay, gridColumn, gridRow } from 'styleUtils/grid';
import { onIe } from 'styleUtils/browserSupport';

export const ProfileWrapper = styled.div`
    ${gridDisplay()}
    ${gridColumn({ start: 1, end: 3 })}
    ${gridRow({ start: 1 })}
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;

    ${fromMedium`
        ${gridColumn({ start: 1, end: 1 })}
        ${gridRow({ start: 1, end: 1 })}
        margin-bottom: 0;

        ${onIe` 
            margin-left: 5rem;
        `}
    `};

    // Center on IE
    ${onIe`
        margin-left: 10rem;
    `}
    ${fromSmall`
        ${onIe` 
            margin-left: 13rem;
        `}
    `}
    ${fromMedium`
        ${onIe` 
            margin-left: 5rem;
        `}
    `}
    ${fromExtraLarge`
        ${onIe` 
            margin-left: 6.5rem;
        `}
    `};
`;
