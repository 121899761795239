import { ValidationRule, FormControls } from 'types/form';

export const initialState: FormControls = {
    firstName: {
        value: '',
        touched: false,
        valid: false,
        validationRules: [],
    },
    lastName: {
        value: '',
        touched: false,
        valid: false,
        validationRules: [],
    },
    address: {
        value: '',
        touched: false,
        valid: false,
        validationRules: [],
    },
    phone: {
        value: '',
        touched: false,
        valid: false,
        validationRules: [],
    },
    email: {
        value: '',
        touched: false,
        valid: false,
        validationRules: [ValidationRule.isRequired, ValidationRule.isEmail],
    },
    resume: {
        value: null,
        touched: false,
        valid: false,
        validationRules: [ValidationRule.isPDF],
    },
    motivation: {
        value: null,
        touched: false,
        valid: false,
        validationRules: [ValidationRule.isRequired],
    },
    consent: {
        value: false,
        touched: false,
        valid: false,
        validationRules: [ValidationRule.isTrue],
    },
};
